.don_dialog_msg
  .item-order-change-ordered-to-preparing
    &__description1
      margin-bottom 20px
    &__description2
      border 1px solid $gray-s2
      border-radius 8px
      padding 16px 20px 14px
      display flex
      justify-content space-between
      align-items center
      > *
        &:nth-child(1)
        &:nth-child(3)
          width 100px
        color $gray-3
        &:nth-child(2)
          width 16px
          height 16px
        &:last-child
          color $blue-1
          font-weight bold

.pb-item-order
  .main-section__table-v2
    .table
      &__th, &__td
        &[data-key="진행상태"]
        &[data-key="상품번호"]
        &[data-key="배송정책"]
        &[data-key="수령인 연락처1"]
        &[data-key="연락처2"]
        &[data-key="배송지"]
        &[data-key="우편번호"]
        &[data-key="배송메모"]
        &[data-key="상품금액"]
        &[data-key="배송비"]
        &[data-key="결제수단"]
        &[data-key="주문구분"]
        &[data-key="상품타입"]
        &[data-key="환불여부"]
        &[data-key="주문구분"]
        &[data-key="구매확정일"]
          display none
