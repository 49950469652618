.header-section
  margin-bottom pxToRem(30px)
  &__title
    color $gray-1
    margin-bottom pxToRem(10px)
    font-size pxToRem(22px)
  &__description
    color $gray-2
    font-size pxToRem(14px)
.pagination-item
  a
    padding 7px 8px 6px !important
    &.current
      background-color $blue-1
      color: #fff !important
      border-radius 8px
.main-section
  margin-top ptr(24)
  padding pxToRem(25px) pxToRem(25px) pxToRem(35px)
  background $gray-6
  border-radius pxToRem(8px)
  display flex
  flex-direction column
  height fit-content
  &__table
    overflow-x scroll
    margin-bottom pxToRem(10px)
    img
      width pxToRem(44px)
      height pxToRem(44px)
      object-fit contain
    background #fff
    border 1px solid $gray-s2
    min-height pxToRem(400px)
    tbody
      tr:hover
        background-color $blue-8
    .checkbox
      border-right 1px solid $gray-s1
    input[type="checkbox"]
      width pxToRem(18px)
      height pxToRem(18px)
      margin pxToRem(20px)
    input[type="text"]
      text-align center
      border-radius 2px
      border 1px solid $gray-s1
      width 80%
      height 60%
    select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size pxToRem(18px) pxToRem(18px)
      font-size pxToRem(14px)
      width 80%
      height 60%
      text-indent pxToRem(10px)
    .link
      cursor pointer
      text-decoration underline
    table
      border-collapse collapse
      min-width pxToRem(1500px)
      width 100%
    tr
      td
      th
        height pxToRem(57px)
        text-align center
      td
        border-bottom 1px solid #F2F2F2
    thead
      th
        font-size pxToRem(14px)
        font-weight 600
        border-bottom 1px solid #000
    tbody
      tr
        font-size pxToRem(14px)
  &__buttons
    button
      background #fff
      font-weight 500
      font-size pxToRem(13px)
      border 1px solid $gray-10
      border-radius 2px
      padding pxToRem(8px) pxToRem(18px)
      min-height ptr(40)
      &:last-child
        margin-right 0