
.main-section
  .tooltip-icon2
    margin-bottom 0 !important
    ul
      margin 0
  &__table
    flex 1
    margin-top pxToRem(15px)
    table
      min-width pxToRem(2500px)
  &__change-status
    display flex
    justify-content center
    align-items center
    min-width ptr(160)
    height 40px
  &__buttons
    display flex
    justify-content space-between
    row-gap 16px
    @media screen and (max-width: 1024px)
      flex-direction column
      & select + div
        flex 1
        & > input[type="text"]
          width 100%
      & .main-section__buttons_right > .main-section__buttons__status,
      & .main-section__buttons_right > .main-section__buttons__related-excel,
      & .main-section__buttons_right button
        width 100%

    button
      border-radius 4px
      border 1px solid $gray-10
      color $gray-80
    &__status
      display flex
      min-height 40px
      select, input, button
        height 100%
    &__status
      &__body
        display flex
        & > input
          margin-right ptr(8)
    @media screen and (min-width: 530px)
      .empty
        width 0
      .change-delivering-all
        display flex
        min-height 40px
    select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      margin-right pxToRem(8px)
      background $image-down-arrow no-repeat 95% center
      background-size pxToRem(18px) pxToRem(18px)
      background-color #fff
      min-width 150px
      font-size pxToRem(14px)
      text-indent pxToRem(10px)
      min-height 40px
      @media screen and (max-width: 1024px)
        min-width 120px
      &[data-value=""]
        color $gray-50
    input[type="text"]
      text-indent pxToRem(10px)
    &__status__label
      display flex
      align-items center
      color $gray-80
      margin-right ptr(8)
      min-width ptr(50px)
  &__buttons_left
    display flex
    column-gap ptr(24)
    flex-wrap wrap
    row-gap 10px
  &__buttons_right
    display flex
    column-gap ptr(8)
.search-select
  input[type="text"]
    border 1px solid $gray-s1
  select
    &[name="cate_item_id"]
      .option
        display none
        &.is_visible
          display block
.n_pb_item_order--masking
  filter blur(5px)
.masking-notice
  display flex
  align-items center
  column-gap pxToRem(4)
  color $gray-s4
  margin-top pxToRem(24)
