.main-section
  background-color $gray-5
  padding ptr(24)
  .table__wrapper
    position relative
    min-height 558px
    overflow-x auto
  .main-section__pagination
    display block
  &[data-total_page_count="1"]
    .main-section__pagination
      display none
    .table__wrapper
      position relative
      min-height 558px
  &__pagination
    margin-top ptr(24)
    display flex
    justify-content center
    align-items center
  &__empty
    display flex
    align-items center
    justify-content center
    flex-direction column
    width 100%;
    padding ptr(160) 0
    row-gap ptr(36)
    color $gray-80
  &__empty_image_wrap
    width ptr(140)
    height ptr(89)
    img
      object-fit contain
      width 100%
      height 100%

.main-section__table-v2
  .table
    background #fff
    border 1px solid $gray-10
    border-radius 8px
    overflow hidden
    input[type="checkbox"]
      width pxToRem(17px)
      height pxToRem(17px)
      cursor pointer
    input[type="text"]
    select
      text-align center
      height pxToRem(30px)
      width 100%
      border 1px solid $gray-s1
      border-radius 2px
    &__head
      background #fff
      z-index 2
      color $black
      display block
    &__body
      z-index 1
      color $gray-80
    &__row
      display flex
    &__th
    &__td
      font-size pxToRem(14px)
      padding 0 4px
      display flex
      justify-content center
      align-items center
      flex-shrink 0
      >div
        limitLine(2)
        word-break keep-all
        word-wrap break-word
    &__right
      width 100%
    &__head
      .table__row
        height pxToRem(56px)
      .table__th
        border-bottom 1px solid #000
        font-weight 600
        background-color $white
    &__body
      .table__row
        min-height pxToRem(72px)
      .table__td
        border-bottom 1px solid $gray-s1
    .checkbox
    .checkbox_all
      width 57px
    [data-key="주문구분"]
    [data-key="상품타입"]
      display none
    [data-key="진행상태"]
      width 112px
      text-align center
    [data-key="주문일시"]
    [data-key="주문번호"]
    [data-key="상품번호"]
    [data-key="옵션"]
    [data-key="수량"]
    [data-key="주문자 이름"]
    [data-key="수령인 이름"]
    [data-key="연락처1"]
    [data-key="연락처2"]
    [data-key="우편번호"]
    [data-key="상품금액"]
    [data-key="배송비"]
    [data-key="결제수단"]
    [data-key="환불여부"]
    [data-key="구매확정일"]
    [data-key="합배송번호"]
    [data-key="요청일시"]
    [data-key="완료일시"]
    [data-key="환불 수량"]
    [data-key="환불 금액"]
    [data-key="환불 사유"]
    [data-key="상품 가격"]
      text-align center
      width 112px
    [data-key="택배사"]
    [data-key="배송정책"]
    [data-key="상품명 별칭"]
    [data-key="스토어"]
      text-align center
      width 150px
    [data-key="주문자 이메일"]
    [data-key="수령인 연락처1"]
      text-align center
      width 144px
    [data-key="상품명"]
      text-align left
      flex 1
      min-width 510px
      a
        color #000 !important
      & > div
        width 100%
    [data-key="운송장번호"]
      text-align center
      width 130px
    [data-key="배송지"]
      text-align left
      width 500px
      &.table__td
        justify-content start
    [data-key="배송메모"]
      text-align left
      width 250px
      &.table__td
        justify-content start
    [data-key="상품번호"]
      cursor pointer
    [data-key="상품명 별칭"]
    &__td:has(.to-pb-item-order-detail)
      cursor pointer
    .product_name_wrap
      display flex
      align-items center
      column-gap ptr(12)
      width 100%
      padding 0 4px
    .thumbnail_wrap
      flex-shrink 0
      width ptr(40)
      height ptr(40)
      overflow hidden
      border-radius 4px