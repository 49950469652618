.question-frame
  &.don_frame[is_modal="true"]
    > .don_wrapper
      width 608px
      >.header
        width 100%
        box-sizing border-box
        padding pxToRem(16px)
        margin 0
        height pxToRem(56px)
        border-bottom 1px solid $gray-5
        >.title
          {$kr-16-b}
    .don_tab
      > .don_wrapper
        position static
  .w_br
  .m_br
    display block

.store-qna-editor
  padding pxToRem(16px)
  padding-bottom calc(env(safe-area-inset-bottom) + 136px)
  padding-bottom calc(constant(safe-area-inset-bottom) + 136px)
  hr
    margin-top pxToRem(20px)
    margin-bottom pxToRem(20px)
    border 0
    border-top 1px solid #f2f2f2
    visibility hidden
  &__required
    display none
  &__header
    display none
  &__error-msg
    display none
    margin pxToRem(7px) 0 0
    padding 0
    font-size pxToRem(13px)
    font-weight 500
    line-height 1.38
    letter-spacing -0.5px
    color #f66565
    &--show
      display block
  &__container
    width 100%
    display flex
    column-gap pxToRem(16px)
  &__controls
    display flex
    flex-direction column
    row-gap pxToRem(24px)
  &__control
    width 100%
    position relative
    &:firat-child
    &--mt0
      margin-top 0
  &__control-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__label
    display block
    &--inline
      display inline-flex
      align-items center
      input
        margin-bottom pxToRem(4px)
        margin-right pxToRem(5px)
    &--lm10
      margin-left pxToRem(10px)
    &--lm-minus3
      margin-left pxToRem(-3px)

  &__label-txt
    margin-bottom pxToRem(8px)
    color $black
  &__label-txt-sub
    font-weight 400
    color #757575
  &__label-txt-sub2
    font-weight 400
    color #3d89f9
  &__body
    position relative
    min-height pxToRem(48px)
    border none
    border-bottom 1px solid $gray-10
    &--error
      border-bottom 1px solid #f66565
    &--radio
    &--check
      min-height auto
      padding pxToRem(24px) 0
      border 0 none
      border-radius 0
      display inline-flex
      align-items center
      justify-content space-between
      width 100%
      & .mshop-core__checkbox__title
        color $black
    &--is_public
      min-height pxToRem(20px)
    &--textarea
      padding pxToRem(12px) 0
      border-bottom 1px solid $gray-10
    .flatpickr-wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0
    &--terms
      padding pxToRem(10px)
      height pxToRem(160px)
      overflow auto
    &--file
      width 100%
      position relative
      border-bottom 1px solid $gray-10
      &[can_cancel="false"]
        .store-qna-editor__body-file-del
          display none
  &__body-area
    position relative
    &--cols2
      display grid
      grid-gap pxToRem(10px)
      grid-template-columns repeat(2, 1fr)
  &__body-input
  &__body-textarea
    color $black
    outline none
    &::placeholder
      color $gray-50
  &__body-input
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    padding pxToRem(12px) 0
    border none
  &__body-textarea
    padding 0
    width 100%
    height pxToRem(140px)
    resize none
    border none
  &__body-textarea-length
    margin-top pxToRem(15px)
    text-align right
    color $gray-50
  &__txt1
    margin pxToRem(8px) 0 0
    color $gray-50
    &--icon1
      display flex
      justify-content flex-start
  &__icon-important
    display none
  &__body-right
    position absolute
    top 0
    right 0
    bottom 0
    padding 0 pxToRem(11px)
    display flex
    align-items center

  &__control--file
    border 1px solid transparent
  &__control-wrap
    display grid
    width 100%
    height pxToRem(48px)
    grid-template-columns auto pxToRem(160px)
    column-gap pxToRem(8px)
  &__input-file
    position absolute
    top 0
    left 0
    width 0
    height 0
    opacity 0
  &__body-file-text
    position absolute
    top 50%
    left 0
    transform translateY(-50%)
    limitLine(1)
    color $gray-50
    &.active
      color $black
  &__body-file-del
    position absolute
    top 50%
    right pxToRem(20px)
    transform translateY(-50%)
    cursor pointer
    img
      width pxToRem(20px)
  &__btn-file-area
    display flex
    align-items center
    justify-content center
    label
      position relative
      display block
      width 100%
      height 100%
      overflow hidden
  &__btn-file
    width 100%
    height 100%
    box-sizing border-box
    border-radius pxToRem(8px)
    border none
    background-color $blue-10
    color $blue-100
    text-align center
    {$button-white-theme}
  &__agree-view
    cursor pointer
    color $gray-50
    text-decoration underline
  &__buttons
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding pxToRem(16px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    &:after
      display block
      content ''
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    & > button
      width 100%
      height pxToRem(56px)
      border-radius pxToRem(8px)
      padding 0
  &__btn-submit
    color $gray-50
    background-color $gray-5
    border 0 none
    transition background-color 200ms ease-out
    pointer-events none
    &.active
      pointer-events auto
      color $white
      background-color $blue-100
      &:hover
        background-color $blue-10 !important
        color $blue !important
  &__btn-cancel
    color $gray-80
    border 1px solid $gray-20
    background-color $white
    margin-right ptr(16)
    {$button-line-theme}
  &__agree-underline
    text-decoration underline

.question-alert
  h2
    margin 0 0 pxToRem(12px) !important
    padding 0 !important
    font-size pxToRem(20px) !important
    font-weight 800 !important
    font-style normal !important
    line-height 1.3 !important
    letter-spacing -0.3px !important
    text-align center !important
    color #111 !important
  p
    margin 0 0 pxToRem(12px) !important
    padding 0 !important
    font-size pxToRem(15px) !important
    font-weight normal !important
    line-height 1.73 !important
    letter-spacing -0.2px !important
    text-align center !important
    color #111 !important
    a
      font-size pxToRem(15px) !important
      font-weight normal !important
      line-height 1.73 !important
      letter-spacing -0.2px !important
      text-align center !important
      color #111 !important
      text-decoration underline !important

.question-privacy-frame
  > .don_wrapper
    width 608px !important
    >.header
      width 100% !important
      box-sizing border-box !important
      padding pxToRem(16px) pxToRem(16px) pxToRem(16px) pxToRem(24px) !important
      margin 0 !important
      height pxToRem(56px) !important
      border-bottom 1px solid $gray-5 !important
      & > .title
        font-family Pretendard !important
        font-size 16px !important
        font-style normal !important
        font-weight 700 !important
        line-height 150% !important
    & > .body
      & .title
        display none
  &__body
    border 0 none !important
    margin 0 !important
    padding 0 !important
    overflow hidden !important
    height fit-content !important
    & p
      {$kr-12-m}
      margin-top pxToRem(8px)
      margin-bottom 0 !important
      padding 0 !important
      color $gray-80
  & .terms_body
    padding pxToRem(32px)
    padding-bottom calc(env(safe-area-inset-bottom) + 136px)
    padding-bottom calc(constant(safe-area-inset-bottom) + 136px)
  &__table
    border-spacing 0
    width 100%
    border 1px solid $gray-20
    th
      background-color $gray-5 !important
    th
      box-sizing border-box !important
      height pxToRem(33px) !important
      border 1px solid $gray-20 !important
      font-family Pretendard
      color $gray-80 !important
      font-size 13px !important
      font-style normal !important
      font-weight 700 !important
      line-height 20px !important
      letter-spacing -0.3px !important
    td
      box-sizing border-box !important
      color $gray-80 !important
      font-family Pretendard !important
      font-size 12px !important
      font-style normal !important
      font-weight 400 !important
      line-height 18px !important
      letter-spacing -0.3px !important
      text-align center !important
      border 1px solid $gray-20 !important
      height pxToRem(67px) !important
    td
      font-size pxToRem(12px) !important
  & .option
    position fixed
    bottom 0
    left 0
    right 0
    background-color $white
    padding pxToRem(16px) pxToRem(32px) pxToRem(32px) pxToRem(32px)
    border-top 1px solid $gray-5
    z-index 10
    display flex
    &:after
      display block
      content ''
      padding-bottom env(safe-area-inset-bottom)
      padding-bottom constant(safe-area-inset-bottom)
    & > button
      width 100%
      height pxToRem(56px)
      border-radius pxToRem(8px)
      padding 0
      color $white
      background-color $blue-100 !important
      {$button-blue-theme}
  & .paragraph
    margin 0 !important
