.refund-orders.main-section
  .table
    &__th, &__td
      &[data-key="상품번호"]
      &[data-key="완료일시"]
      &[data-key="환불 범위"]
      &[data-key="배송정책"]
      &[data-key="택배사"]
      &[data-key="운송장번호"]
      &[data-key="배송정책"]
      &[data-key="운송장번호"]
      &[data-key="연락처2"]
      &[data-key="배송지"]
      &[data-key="우편번호"]
      &[data-key="배송메모"]
      &[data-key="상품금액"]
      &[data-key="상품 가격"]
      &[data-key="배송비"]
      &[data-key="결제수단"]
      &[data-key="주문구분"]
      &[data-key="상품타입"]
      &[data-key="환불여부"]
      &[data-key="주문구분"]
      &[data-key="구매확정일"]
        display none