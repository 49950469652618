.don_tab[tab_name="/modules/MShop/App/Checkout/S/Mui/tab.js"]
  @import "../share.styl"
  @import "../order_info.styl"
  .delivery-type-grouped-item
    &__1
      display flex
      align-items center
      padding pxToRem(16) 0
      border-bottom 1px solid $gray-20
    &__2
      &__item
        border-bottom 1px solid $gray-20
        &:last-child
          border-bottom 0

  .cart-store-item
    padding ptr(16) ptr(24)
    border-radius 8px
    border 1px solid $dim-10
    margin-bottom pxToRem(24px)
    &__1
      display flex
      align-items center
      border-bottom 1px solid $gray-700
      padding pxToRem(22px) 0 pxToRem(20px)
    &__2
      &__item
        border-bottom 1px solid $gray-20
        &:last-child
          border-bottom 0
    &__3
      padding-top pxToRem(24px)
    &__price-set
      &__item
        display flex
        justify-content space-between
        margin-bottom pxToRem(12px)
        width 100%
        &__text
          font-size pxToRem(14px)
          color $gray-600
        &__price
          font-size pxToRem(14px)
          color $gray-800
        &__total-price-text
          font-size pxToRem(14px)
          color $gray-800
          font-weight bold
        &__total-price
          font-size pxToRem(16px)
          font-weight bold
          color $gray-800
    & .cch-style-option-list__item-wrapper
      padding ptr(12)
  .section-show
    display none !important
  .mps-checkout
    display flex
    min-width $pc-layout-body-min-width
    padding-bottom pxToRem(120)
    .accordion
      display flex
      flex-direction column
      align-self stretch
      background-color $blue-10
      border-radius 8px
      margin-top 16px
      padding 16px
      &.expand
        .accordion-content
          margin-top 8px
          max-height 1000px
        .accordion-header
          button.toggle-btn
            &::after
              content 'Close'
              html[lang='jp'] &
                content '簡潔'
      &[is_show="false"]
        display none
      .accordion-header
        display flex
        justify-content space-between
        color $blue-100
        .accordion-title
          display flex
          gap 8px
          font-size 14px
          font-weight 700
          line-height 140%
          svg
            min-width 16px
        button.toggle-btn
          color $blue-100
          width 52px
          text-align right
          background none
          border none
          text-decoration underline
          cursor pointer
          font-weight 500
          &::after
            content 'View'
            html[lang='jp'] &
              content '詳細'
      .accordion-content
        max-height 0
        overflow hidden
        transition all 0.1s ease-in-out
        .accordion-description
          margin-top 0
          color $blue-100
          font-size 12px
          font-weight 500
          line-height 140%
    &__title
      margin-top $default-header-pc_height + ptr(32)
      margin-bottom ptr(24)
      color $black
    &[data-has_inhouse_shippings="true"]
      .pre-shipping-order-item__price-set__label .mps-tooltip-icon
        display inline-block
    &__postcode-layer
      box-sizing border-box
      margin-top pxToRem(10)
      margin-bottom pxToRem(16px)
      display none
      height pxToRem(500)
      width 100%
      overflow hidden
      z-index 100
      border 5px solid $black
      padding-top pxToRem(16)
      position relative
    &__postcode-close
      cursor pointer
      position absolute
      right -3px
      top -3px
      z-index 1
    &__temp-user-infobox
      margin ptr(24) 0
      padding ptr(16)
      display flex
      align-items center
      column-gap ptr(6)
      background-color $blue-10
      border-radius 8px
      &__info-icon
        color $blue-100
        width ptr(20)
        height ptr(20)
      &__text
        color $blue-100
    &__left
      width pxToRem(896)
      margin-right pxToRem(32)
    &__section
      position relative
      margin-bottom pxToRem(36)
    &__section:not(.agreement)
      padding-bottom pxToRem(0)
    &__section-header
      margin-bottom pxToRem(24)
      padding ptr(12) 0
      position relative
      border-bottom 1px solid $black
      color $black
      &.no-underline
        border-bottom none
        padding 0
        margin-bottom ptr(8)
    &__section-body
      position relative
    &__input-wrap
    &__input-wrap2
      position relative
      display flex
      flex-direction column
      margin-bottom pxToRem(24)
      &:last-child
        margin-bottom 0
      label
        color $black
        margin-bottom pxToRem(8)
      input
        &::placeholder
          color $gray-50
        &:disabled
          background $white
      input
      select
      span.value
        border none
        border-radius 0
        border-bottom 1px solid $gray-10
        padding ptr(12) 0
        width 100%
        display flex
        box-sizing border-box
        outline transparent
        -webkit-appearance none
        -moz-appearance none
        appearance none
        color $black
      select
        -webkit-appearance none
        -moz-appearance none
        background url("//s3.marpple.co/files/u_2308668/2024/5/original/995524efc0c078c813058ba64e263d767cca12e81.svg") no-repeat
        background-position right pxToRem(16) top pxToRem(16)
        background-size pxToRem(16) pxToRem(16)
        border 1px solid $gray-10
        border-radius 8px
        padding ptr(12)
        color $gray-50
        html[lang="en"] &
        html[lang="jp"] &
          color $black
        &.active
          color $black
      .email_text
        .btn
          position absolute
          top 0
          right 0
          border 0 none
          background transparent
          color $gray-50
      .my_point:not(:empty)
        display block
        color $blue-100
        margin-top pxToRem(8)
      .promotion_description:not(:empty)
        display block
        color $gray-50
        margin-top pxToRem(8)
    &__msg
      color $gray-50
      margin-top pxToRem(8)
    &__phone-number
      display flex
      flex-direction row
      column-gap pxToRem(16)
    &__optional
      margin-left pxToRem(4)
      color $gray-50
    &__input-wrapper
      display flex
      button
        flex-shrink 0
        min-width ptr(200)
        margin-left ptr(8)
        transition: background-color 200ms ease-out, border 200ms ease-out;
        &.active
          background-color $blue-100
          color $white
          cursor: pointer
          &:hover
            background-color $blue-10
            color $blue

    &__check-box
      border-radius 0
      display flex
      align-items center
      justify-content flex-end
      column-gap pxToRem(16)
      position absolute
      top pxToRem(-64)
      right 0
      .mshop-core__checkbox__title
        color $black
      .mshop-core__checkbox__sub-title
        color $gray-80
        margin-top pxToRem(4)
      &--agree
      &--en-jp
        color $gray-700
        position relative
        top auto
        left auto
        justify-content flex-start
        align-items flex-start
      &--required
        margin 0 pxToRem(6) 0 0
        font-weight bold
      .mp_input
        display flex
      label.check
        display flex
        align-items center
        input[type="checkbox"] + span
          margin 0 pxToRem(12) 0 0
          width pxToRem(18)
          height pxToRem(18)
          border 1px solid $gray-300
          border-radius 0
          &:after
            width pxToRem(16)
            height pxToRem(16)
            top 0
            left 0
            background $gray-800 url(//s3.marpple.co/files/u_14345/2021/5/original/21cb1a210300cd68d9d95a6d5bac1b369856afc51.svg) no-repeat 0 0
            background-size pxToRem(16) pxToRem(16)
    &__payment-grid
      display grid
      grid-template-columns repeat(auto-fit, minmax(ptr(172.8), 1fr))
      grid-gap ptr(8)
    &__payment-type
      border-radius 8px
      height ptr(56)
      border 1px solid $gray-20
      background transparent
      color $gray-80
      position relative
      transition: background-color 200ms ease-out, border 200ms ease-out;
      display flex
      align-items center
      justify-content center
      &:hover
        border 1px solid $blue
        color $blue
      &:active
        background-color $blue-100
        border-color $blue-100
        color $white
        .mps-checkout__payment-icon
          display none
          &--active
            display flex
    &__payment-icon
      width pxToRem(24)
      height pxToRem(24)
      margin-right pxToRem(8)
      &--active
        display none
    &__payment-info
      {$kr-12-b}
      position absolute
      top 0
      left 50%
      white-space nowrap
      transform translate(-50%,-50%)
      padding pxToRem(3.5) pxToRem(8)
      border-radius pxToRem(24)
    .payment_types
      .payment_type
        display none
      &[type="card"] > .card
      &[type="without_bank"] > .without_bank
      &[type="trans"] > .trans
      &[type="paypal"] > .paypal
      &[type="alipay"] > .alipay
        display block
      &[type="card"] button[payment_type="card"]
      &[type="without_bank"] button[payment_type="without_bank"]
      &[type="trans"] button[payment_type="trans"]
      &[type="offline_card"] button[payment_type="offline_card"]
      &[type="offline_cash"] button[payment_type="offline_cash"]
      &[type="samsung"] button[payment_type="samsung"]
      &[type="payco"] button[payment_type="payco"]
      &[type="naverpay"] button[payment_type="naverpay"]
      &[type="escrow"] button[payment_type="escrow"]
      &[type="ssgpay"] button[payment_type="ssgpay"]
      &[type="lpay"] button[payment_type="lpay"]
      &[type="paypal"] button[payment_type="paypal"]
      &[type="alipay"] button[payment_type="alipay"]
      &[type="vbank"] button[payment_type="vbank"]
      &[type="tosspay"] button[payment_type="tosspay"]
      &[type="kakaopay"] button[payment_type="kakaopay"]
      &[type="PAYMENTWALL_CREDIT_CARD"] button[payment_type="PAYMENTWALL_CREDIT_CARD"]
      &[type="PAYMENTWALL_KONBINI"] button[payment_type="PAYMENTWALL_KONBINI"]
      &[type="KOMOJU_PAY_EASY"] button[payment_type="KOMOJU_PAY_EASY"]
      &[type="KOMOJU_KONBINI_DAILY_YAMAZAKI"] button[payment_type="KOMOJU_KONBINI_DAILY_YAMAZAKI"]
      &[type="KOMOJU_KONBINI_LAWSON"] button[payment_type="KOMOJU_KONBINI_LAWSON"]
      &[type="KOMOJU_KONBINI_FAMILY_MART"] button[payment_type="KOMOJU_KONBINI_FAMILY_MART"]
      &[type="KOMOJU_KONBINI_MINISTOP"] button[payment_type="KOMOJU_KONBINI_MINISTOP"]
      &[type="KOMOJU_KONBINI_SEICOMART"] button[payment_type="KOMOJU_KONBINI_SEICOMART"]
        background-color $blue-100
        border-color $blue-100
        color $white
        .mps-checkout__payment-icon
          display none
          &--active
            display flex
      &[type="naverpay"] .naverpay-info
        display flex

    &__input-mb-8
      margin-bottom pxToRem(8)
    &__input-mb-24
      margin-bottom pxToRem(24)
    &__input-mb-48
      margin-bottom pxToRem(48)
    &__input-mt-24
      margin-top pxToRem(24)
    &__input-memo2
      margin-top pxToRem(8)

    &__inhouse
      &--hide
        display none

    &__promotion-code-apply
      &:after
        content 'APPLY'
        html[lang="kr"] &
          content '쿠폰 적용'
        html[lang="jp"] &
          content '適用'
      &[applied="true"]
        background $gray-800
        color #fff
        &:after
          content 'CANCEL'
          html[lang="kr"] &
            content '쿠폰 취소'
    &__agree-list
      display flex
      flex-direction column
      gap pxToRem(12px)
      margin-bottom pxToRem(16px)
      color $gray-600
    &__check-agree-list
      display flex
      flex-direction column
      gap pxToRem(16px)
    &__agree-description
      display none
    &__agree-flex
      display flex
      justify-content space-between
      align-items center
      color $black
    &__agree-arrow
      background url("//s3.marpple.co/files/u_29089/2023/9/original/1d1b7ee16c40a0cd35d1a4945286c7c6598189ad1.svg") no-repeat
      background-size pxToRem(15) pxToRem(15)
      width pxToRem(15)
      height pxToRem(15)
    &__agree-view
      color $gray-80
      text-decoration underline
    &__agree-toggle
      cursor pointer
      &--open
        .mps-checkout__agree-description
          display block
          margin-top pxToRem(8)
          word-break keep-all
          color $gray-80
        .mps-checkout__agree-arrow
          transform rotate(180deg)
    &__submit-wrap
      width 100%
    &__submit-btn
      border 0
      width 100%
      border-radius pxToRem(8)
      padding pxToRem(16) 0
      transition: background-color 200ms ease-out, border 200ms ease-out;
      &:not(.mps-checkout__submit-btn--disabled)
        &:hover
          background-color $blue-10 !important
          color $blue !important
      &--disabled
        color $gray-50 !important
        background-color $gray-5 !important
    /*--------------- EN JP ---------------------*/
    .bar_wrapper
      html[lang="en"] &
      html[lang="jp"] &
        display flex
        width 100%
        column-gap ptr(32)
        .left_bar
          order -1
          flex 1
        .right_bar
          flex 0 0 ptr(448)
        .shipping_company_info
          display none
          &.oversea
            display block
          select.express.disabled,
          select.express_service.disabled
            cursor default
            background-color #F5F5F5
            pointer-events none


    &__shipping-company-item
      display flex
      align-items center
      &:first-child
        margin-right pxToRem(16)
    &__shipping-company-img
      position relative
      height pxToRem(24)
      margin-right pxToRem(6)
    &__shipping-company-infos
      margin-top pxToRem(12)
      th
        width pxToRem(144)
        vertical-align middle
        text-align left
      th
      td
        padding-bottom ptr(24)
    &__payment-type-info
      ul
        padding-left pxToRem(18)
        margin-bottom 0
        html[lang="en"] &
        html[lang="jp"] &
          margin-top ptr(16)
    &__agree-en
      color $black
    &__agree-underline
      text-decoration underline

    /*-------------------------------------*/

    .inhouse-checkout-shipping
      &__select-type
        overflow hidden
        display flex
        border 1px solid #ddd
        border-radius pxToRem(8)
        margin-bottom pxToRem(20)
      &__type-btn
        border none
        padding pxToRem(16) pxToRem(12)
        margin 0
        background #fff
        flex 1
        text-align center
        &--selected
          background #000
          *
            color #fff !important
      &__type-btn-title
        display flex
        align-items center
        justify-content center
        color $gray-600
        margin-bottom pxToRem(4)
        img
          width pxToRem(20)
          margin-right pxToRem(4)
      &__type-btn-description
        color $gray-600
      &__method
        padding pxToRem(20)
        background #f8f8f8
        border-radius pxToRem(8)
        color #4e4e4e
        margin-bottom pxToRem(40)
      &__method-title
        margin-bottom pxToRem(4)
      &__method-ul
        list-style none
        margin 0
        padding 0 pxToRem(4)
        li
          display flex
          gap pxToRem(4)
    &__flex-align-item-center
      display flex
      align-items center
  .naverpay-info
    display none
    {$kr-12-m}
    margin-top pxToRem(24)
    padding pxToRem(16)
    background-color $blue-10
    border-radius pxToRem(8)
    &__title
      flex-shrink 0
      color $blue-100
      margin-right pxToRem(20)
    &__description
      color $gray-80
  .right_bar
    width pxToRem(448)
    &__title
      color $black
    &__btns
      display none
    &__header
      display none
    &__content
      padding-bottom pxToRem(120)
    .checkout-order-info
      &__2
        padding ptr(16) ptr(24)
        border-radius pxToRem(8)
        border 1px solid $gray-20
        .up-item-list-price-info
          &__3.delivery-fee-info
            display none
.don_frame[frame_name="mp.checkout.change_email"]
  > .don_wrapper
    max-width pxToRem(475) !important
.don_page[page_name="mp.checkout.change_email"]
  background-color #fff
  .don_wrapper >.header
    display none
  .don_wrapper >.page_body
    padding 0 pxToRem(50) pxToRem(50)
    .header_title
      display none
    .new_email
      margin-bottom ptr(24)
    .email_text
      padding pxToRem(14) pxToRem(24) pxToRem(16)
      border-radius pxToRem(4)
      border solid pxToRem(1) #dddddd
      width 100%
      box-sizing border-box
      margin-bottom pxToRem(6)
    .title
      margin-top pxToRem(24)
      font-size pxToRem(16)
      margin-bottom pxToRem(10)
    .current_data
      .title
        margin-top 0
    .notice
      color $gray-50
    .inputs
      margin-bottom pxToRem(24)
      input
      .input
        border none
        border-radius 0
        border-bottom 1px solid $gray-10
        padding ptr(12) 0
        width 100%
        display flex
        box-sizing border-box
        outline transparent
        -webkit-appearance none
        -moz-appearance none
        appearance none
        color $black
    .submit_btn
      width pxToRem(375)
      height pxToRem(52)
      margin pxToRem(32) 0 0
      border-radius pxToRem(8)
      font-weight bold
      cursor pointer
      background-color $blue-100
      color $white
      display flex
      align-items center
      justify-content center
      transition: background-color 200ms ease-out, border 200ms ease-out;
      &:hover
        background-color $blue-10
        color $blue
.mps-checkout__input-wrap.has_not_email
  .fx-auto-complete-list
    right 0
    top pxToRem(73)
    ul.fx-auto-complete-list-wrap
      background #fff
      border 1px solid #e7e7e7
    li.auto-domain
      padding pxToRem(12)
      font-size pxToRem(16)
      &[selected="true"]
        background-color #ebf3fe !important
        color #1c75ff !important
  &.has_not_email--en-jp
    .fx-auto-complete-list
      top pxToRem(40)
