@import './share-global.styl';
// @Mixins
@import './style_mixins.styl';
@import './frame.modal.styl'
@import './variables2.styl';
@import './color.styl';
@import './typography.styl';

// 마플샵 2.0
@import 'marppleshop2/Share/index.styl';

// @Classes
$app = '.app'
$creator = '.creator'
$bottom-tab-bar = '.bottom-tab-bar'

// @Variables
pc-break-point = 1440px
pc-max-width = pxToRem(1720)
@import './don.frame.pc.styl'
@import "login_pc.styl"
@import '../all/unsupported_browser_notice.styl'

// core
@import "../../../modules/MShop/Core/F/Style/Pc/index.styl"

// @Global
html[is_mobile=false]
  font-size 14px
  overflow-y scroll
  @media screen and (max-width pc-break-point)
    :where(&)
      font-size .9722vw
  @media screen and (max-width: 1023px)
    :where(&)
      font-size: 10px
body.body-fixed
  position fixed !important
  top 0
  left 0
  right 0
  bottom 0

h1, h2, h3, h4, h5, h6, b, string
  font-weight 600

#don_up
  display none !important
body.mshop
  textarea
  input
    font-family Pretendard, AppleSDGothicNeo, -apple-system, BlinkMacSystemFont, "Droid Sans", "Roboto", "Segoe UI", "Helvetica", Arial, sans-serif
  &.is-studio-iframe
    #don_frame_screen
      display block !important
  .w_br
    display block
  /* 마플샵 메인 */
  @import '../../../modules/Creator/Home/F/Style/Pc/home.styl';
  @import '../../../modules/MShop/App/Layout/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Main/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Home/F/Style/Pc/index.styl'

  @import '../../../modules/MShop/App/Adult/Foreigner/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Adult/F/Style/Pc/index.styl'

  @import '../../../modules/MShop/App/Product/Sub/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Today/F/Style/Pc/app-today.styl'
  @import '../../../modules/MShop/App/Seller/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Seller/BannerList/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Keyword/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Keyword/Drawer/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Community/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Community/Detail/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Live/F/Style/Pc/index.styl'



  /* NFT */
  @import '../../../modules/MShop/App/Nft/F/Style/Pc/index.styl'

  /* 셀러샵 */
  @import '../../../modules/MShop/App/Store/Community/Editor/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Store/Community/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Store/About/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Store/About/EditModal/F/Style/Pc/index.styl'

  /* 문의하기 */
  @import '../../../modules/MShop/App/Store/Question/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Store/QuestionList/F/Style/Pc/index.styl'

  /* 마이페이지 */
  @import '../../../modules/MShop/App/Mypage/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Mypage/Like/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Mypage/Community/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Mypage/Recent/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Mypage/Account/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/Mypage/Withdrawal/F/Style/Pc/index.styl'
  @import "../../../modules/MShop/App/CustomerOrderReceipt/F/Style/Pc/index.styl"

  /* 상품상세 */
  @import '../../../modules/MShop/App/ProductDetail/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/ProductDetail/Modal/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/TokenGate/StoresProduct/F/Style/Pc/index.styl'

  @import '../../../modules/MShop/App/ProductDetail/Rune/F/Style/Pc/index.styl'

  /* 장바구니 */
  @import '../../../modules/MShop/App/Cart/F/Style/Pc/index.styl'

  /* 구매내역 */
  @import '../../../modules/MShop/App/CustomerOrders/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/CustomerOrders/Modal/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/CustomerOrder/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/App/CustomerOrder/AdditionalPay/F/Style/Pc/index.styl'
  @import '../../../modules/MShop/ShareFrame/RefundBank/F/Style/Pc/index.styl'

  @import '../../../modules/MShop/App/ShippingInfo/F/Style/Pc/index.styl'

  /* 주문서 */
  @import '../../../modules/MShop/App/Checkout/F/Style/Pc/index.styl'

  @import '../../../modules/MShop/App/UpItem/F/Style/Pc/index.styl'

  /* 컴포넌트 */
  @import '../../../modules/Components/BottomTabBar/F/Style/Pc/index.styl'
  @import '../../../modules/Components/Drawer/F/Style/Pc/index.styl'
  @import '../../../modules/Components/Footer/F/Style/Pc/index.styl'
  @import '../../../modules/Components/Header/F/Style/Pc/index.styl'

  /* 마플샵 2.0 스타일 */
  @import 'marppleshop2/Pc/index.styl';

@import '../../../modules/Components/Layout/F/Style/Pc/index.styl'

/* 공통 */
@import '../../../modules/MShop/Util/F/Style/Pc/toast.styl'
@import '../../../modules/MShop/Util/F/Style/Pc/tooltip.styl'


//pb 리스트 스타일
@import '../../../modules/MShop/Studio/ListTemplate/F/Style/Pc/index.styl'

  /* 새로운 플러스 어드민 */

@import '../../../modules/MShop/ShareFrame/PopUp/F/Style/Pc/index.styl';

// 나중에 body.mshop 밑으로 넣을 예정
@import '../../../modules/MShop/App/Product/Item/F/Style/Pc/index.styl'

/* 셀러 신청 */
@import '../../../modules/MShop/App/AdRes/F/Style/Pc/index.styl'
@import '../../../modules/MShop/App/AdRes/TermsModal/F/Style/Pc/index.styl'
@import '../../../modules/MShop/App/AdRes/Finish/F/Style/Pc/index.styl'
/* 토큰게이팅 */
@import "../../../modules/MShop/Studio/TokenGate/StoresProduct/F/Style/PC/index.styl"

/* faq */
@import '../../../modules/MShop/App/Help/F/Style/Pc/index.styl'
// 스튜디오 새로운 테이블
@import "studio/studio.table.styl"
// 스튜디오 출금 신청
@import "studio/studio.withdraw.styl"

// 스튜디오 상품 등록
@import '../../../modules/MShop/Studio/PbProductCreator/F/Style/Pc/index.styl'

// 스튜디오 디지털/전자책 등록
@import '../../../modules/MShop/Studio/DigitalProductCreator/F/Style/Pc/index.styl'

// 스튜디오 샵 정보
@import '../../../modules/MShop/Studio/ShopInfo/F/Style/Pc/index.styl'
// pb주문서내역
@import '../../../modules/MShop/Studio/PbOrders/F/Style/Pc/index.styl'
@import '../../../modules/MShop/Studio/PbItemOrders/F/Style/Pc/index.styl'
// 디지털/전자책 주문내역
@import '../../../modules/MShop/Studio/DigitalProductItemOrders/F/Style/Pc/index.styl'

// 환불클레임 관련
@import '../../../modules/MShop/Studio/RefundOrders/F/Style/Pc/index.styl'

// 셀러 정보
@import '../../../modules/MShop/Studio/EcommerceInfo/F/Style/Pc/index.styl'
// 배송 정책 추가하기
@import '../../../modules/MShop/Studio/EcommerceInfo/StoresDeliveryCompany/F/Style/Pc/index.styl'

//@import '../../../modules/MShop/Studio/ListTemplate/F/Style/Pc/index.styl'
// 문의 내역
@import '../../../modules/MShop/Studio/Question/List/F/Style/Pc/index.styl'
@import '../../../modules/MShop/App/Store/QuestionDetail/F/Style/Pc/index.styl'

// pb 상품 관리
@import '../../../modules/MShop/Studio/PbProduct/F/Style/Pc/index.styl'
@import '../../../modules/MShop/Studio/PbProductsManager/F/Style/Pc/index.styl'

@import '../../../modules/MShop/Studio/PbItemOrderDetail/F/Style/Pc/index.styl'

// 오픈 api
@import '../../../modules/MShop/Studio/OpenApi/F/Style/Pc/index.styl'

// pb 팝업
@import '../../../modules/MShop/Studio/PbProductGuidePopup/F/Style/Pc/index.styl'

// 마플샵, 어드민과 공통 사용 부분
@import '../../../modules/MShop/App/Product/Filter/F/Style/Pc/index.styl'
@import '../../../modules/MShop/App/Today/F/Style/Pc/app-today-detail.styl'
@import '../../../modules/Creator/Editor/F/Style/Pc/editor.button.styl'
@import '../../../modules/MShop/App/Today/F/Style/Pc/today.styl'
@import '../../../modules/MShop/App/Product/F/Style/Pc/index.styl'
@import '../../../modules/MShop/App/Store/Product/F/Style/Pc/index.styl'
@import '../../../modules/MShop/Studio/ProductGuidePopup/F/Style/Pc/index.styl'
@import '../../../modules/MShop/Studio/PbProductTip/F/Style/Pc/index.styl'
@import '../../../modules/MShop/Studio/PbProductSize/F/Style/Pc/index.styl'

@import '../../../modules/MShop/PlusAdmin/Product/F/Style/Pc/index.styl'
@import '../../../modules/MShop/PlusAdmin/Youtube/F/Style/index.styl'
@import '../../../modules/MShop/PlusAdmin/Style/F/Style/index.styl'
@import '../../../modules/MShop/PlusAdmin/StyleEditor/F/Style/index.styl'

@import "../../../modules/MShop/Youtube/SelectChannel/F/Style/Pc/index.styl"
@import "../../../modules/MShop/Youtube/Bridge/F/Style/Pc/index.styl"
@import "../../../modules/MShop/Youtube/BridgeShopping/F/Style/Pc/index.styl"

/* 오프라인 팝업스토어 랜딩 */
@import "../../../modules/MShop/Landing/OfflinePopupStore/F/Style/Pc/index.styl"

/* 셀러 신청 랜딩 */
@import "../../../modules/MShop/Landing/Creator/F/Style/Pc/index.styl"

/* 브랜드 커머스 랜딩 */
@import "../../../modules/MShop/Landing/BrandCommerce/F/Style/Pc/index.styl"

/* 신고접수 */
@import '../../../modules/MShop/App/Solution/F/Style/Pc/index.styl'

/* 에러페이지 */
@import '../../../modules/MShop/App/Error/F/Style/Pc/index.styl'


@import "../../../modules/MShop/App/Product/Tag/F/Style/index.styl"

// 기존과 다른 로그인 로직
@import '../../../modules/MShop/Auth/F/Style/Pc/index.styl'
@import '../../../modules/MShop/Core/F/Style/Pc/index.styl'

// 휴면 계정
@import '../../../modules/Creator/Dormant/Cert/F/Style/Pc/index.styl'
@import '../../../modules/Creator/Dormant/PhoneCert/F/Style/Pc/index.styl'
@import '../../../modules/Creator/Dormant/Return/F/Style/Pc/index.styl'

// 아이디 찾기
@import "../../../modules/Creator/Login/FindId/F/Style/Pc/index.styl"
@import "../../../modules/Creator/Login/FindId/Result/F/Style/Pc/index.styl"
@import "../../../modules/Creator/Login/ResetPassword/F/Style/Pc/index.styl"
@import "../../../modules/Creator/Login/ResetPassword/Verify/F/Style/Pc/index.styl"

// crew authorization 회원가입
@import "../../../modules/MShop/ExtraAuth/Crew/F/Style/Pc/index.styl"

// login-header
@import "../../../modules/Creator/Login/Header/F/Style/Pc/index.styl"

// custom-alert
@import "../../../modules/Alert/S/Tmpl/alert_pc.styl"
@import "../../../modules/MShop/App/Components/Item/F/Style/Pc/index.styl"
/* 팝업 */

/* mshop 플러스, nbox 와 함께 리뉴얼 디자인 레이아웃 */
@import './layout/Pc/index.styl';
@import './icon.styl';
@import '../../../modules/MpShopPlus/Main/F/Style/Pc/index.styl'
@import '../../../modules/MpShopPlus/Product/F/Style/Pc/index.styl'
@import '../../../modules/MpShopPlus/Terms/F/Style/Pc/index.styl'
@import '../../../modules/MpShopPlus/Privacy/F/Style/Pc/index.styl'

// 결제 완료
@import "../../../modules/MShop/App/Receipt/F/Style/Pc/index.styl"
// 이용 약관
@import "./terms.styl"

/*팝업스토어*/
@import "../../../modules/MShop/App/PopUpStore/F/Style/Pc/index.styl"
@import "../../../modules/MShop/App/PopUpStore/OrderDetail/F/Style/Pc/index.styl"
@import "../../../modules/MShop/App/PopUpStore/OrderCounts/F/Style/Pc/index.styl"

@import "../../../modules/BpOption/UploadType/F/Style/Pc/index.styl"

@import "./maker_pc.styl"


#creator_layer_pop
#creator_layer_pop2
#creator_layer_pop3
#creator_layer_pop4
#creator_layer_pop5
#creator_layer_pop6
#creator_layer_pop7
#creator_layer_popup_notice
#nbox_layer_pop1
  box-shadow 2px 2px 20px #888
  position absolute
  top 180px
  right 8.714285714285714rem
  z-index 15
  background-color #000
  .banner
    text-align center
    a
      display block
      width 100%
    img
      vertical-align top
    .mobile
      display none
  .one_day_close
    display inline-block
    padding 7px 10px 7px
    color #fff
    cursor pointer
    font-size 12px
  .close
    display inline-block
    padding 7px 10px 7px
    position absolute
    bottom 0
    right 0
    font-size 12px
    color #fff
    cursor pointer
    img
      height 14px


.postcode_layer
  box-sizing border-box
  margin-top pxToRem(10)
  margin-bottom pxToRem(16)
  display none
  height pxToRem(500)
  width 100%
  overflow hidden
  z-index 100
  border pxToRem(5) solid #000
  padding-top pxToRem(16)
  position relative
  background white
  .postcode_close
    cursor pointer
    position absolute
    right pxToRem(-3)
    top pxToRem(-3)
    z-index 1

#creator_layer_popup_notice
  a.link1
    display: block;
    position: absolute;
    top: 48%;
    left: 8%;
    right: 30%;
    bottom: 49%;
    width: auto;
  a.link2
    display: block;
    position: absolute;
    top: 51%;
    left: 8%;
    right: 30%;
    bottom: 46%;
    width: auto;

body.setting-products
  @media screen and (max-width: 1024px)
    #marpplizer
      display none !important
