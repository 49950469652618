.main-section
  &__table
    table
      min-width 57rem !important
    td[data-key="재고수량"]
      input
        text-align center
        width pxToRem(100px)
    tr
      td[data-key = "상품명"]
        max-width pxToRem(150px)
        div
          overflow hidden
          text-overflow ellipsis
          word-break break-all
          white-space nowrap
      td[data-key = "이미지"]
        img
          cursor pointer
          width pxToRem(44px)
          height pxToRem(44px)
      td[data-key = "주문일시"]
      td[data-key = "택배사"]
        width pxToRem(150px)
.search-select
  select
    &[name="cate_item_id"]
      .option
        display none
        &.is_visible
          display block

.inventory_management
  .search-section
    margin-bottom pxToRem(40px)
    &__selects
      padding pxToRem(25px) pxToRem(25px) pxToRem(15px)
      margin-bottom pxToRem(40px)
      border 1px solid $gray-5
    &__group
      display flex
      flex-wrap wrap
    &__buttons
      display flex
      justify-content center
      > button
        width pxToRem(120px)
        height pxToRem(40px)
        display flex
        justify-content center
        align-items center
        font-weight 500
        font-size pxToRem(14px)
        border-radius pxToRem(2px)
        margin-right pxToRem(10px)
        &:last-child
          margin-right 0
    &__reset
      border 1px solid $gray-s1
      color $gray-s3
      background #fff
    &__search
      border 1px solid $blue-1
      color #fff !important
      background $blue-1

  .search-select
    display flex
    > *
      margin-right pxToRem(12px)
      &:last-child
        margin-right 0
    select
      -webkit-appearance none
      -moz-appearance none
      border 1px solid $gray-s1
      border-radius 2px
      background $image-down-arrow no-repeat 95% center
      background-size pxToRem(18px) pxToRem(18px)
      font-size pxToRem(14px)
      font-weight 500
      width 100%
      height 100%
    input
    select
      text-indent pxToRem(10px)
      font-size pxToRem(14px)

  .search
    display flex
    align-items center
    height pxToRem(40px)
    width 33%
    @media screen and (max-width: 1300px)
      width 50%
    @media screen and (max-width: 800px)
      width 100%
    margin-bottom pxToRem(12px)
    &__name
      flex 1
      font-size pxToRem(14px)
      font-weight 600
    &__target
      flex 2
      height 100%
    &__has-text-input
      flex 3
      select
        flex 1
      input
        flex 2
    &__vacant
      flex 1

  .search-date
    display flex
    align-items center
    > div
      position relative
    &__hidden
      position absolute
      width 100%
      height 100%
      top 0
      left 0
      visibility hidden
    &__from
      width 100%
      height 100%
      margin-right 4px
    &__divider
      color $gray-s4
    &__to
      width 100%
      height 100%
      margin-left pxToRem(4px)
    &__input
      display flex
      align-items center
      border 1px solid $gray-s1
      border-radius 2px
      font-size pxToRem(14px)
      font-weight 500
      padding-left pxToRem(10px)
      width 100%
      height 100%