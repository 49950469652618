@import "../share.styl"

.pb-order
  position relative
  .main-section
    height auto
  &__paid-at
    position absolute
    top 10px
    right 0

.pb-order-count
  border-radius 8px
  border solid 1px $gray-5
  display grid
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width 100%
  margin-bottom 20px
  min-height 90px
  [data-order_process_step_id]
    cursor pointer
  &__item
    border-right 1px solid $gray-5
    padding-left ptr(24)
    display flex
    column-gap ptr(12)
    align-items center
    &[data-selected="true"]
      .pb-order-count__name
      .pb-order-count__count
      .pb-order-count__count-kr
        color $blue-100
      .pb-order-count__icon_background
        background-color $blue-100
      .pb-order-count__icon_wrap
        color $white
  &__icon_background
    width ptr(40)
    height ptr(40)
    border-radius 50%
    background-color $gray-5
    display flex
    align-items center
    justify-content center
    &.mini
      width ptr(18)
      height ptr(18)
  &__icon_wrap
    width ptr(24)
    height ptr(24)
    color $gray-50
    display flex
    &.REFUNDED
      width ptr(10)
      height ptr(10)
    &.CANCELED
      width ptr(6.576)
      height ptr(6.576)
  &__info
    flex-shrink 0
  &__name
    color $gray-80
  &__count
    color $black
  &__count-kr
    color $gray-80

  &__cancels
    padding-left 24px
    display flex
    flex-direction column
    justify-content center
  &__item-cancel
    display flex
    align-items center
    margin-bottom 4px
    column-gap ptr(12)
    &[data-selected="true"]
      .pb-order-count__name
      .pb-order-count__count-cancel
      .pb-order-count__count-kr
        color $blue-100
      .pb-order-count__icon_background
        background-color $blue-100
      .pb-order-count__icon_wrap
        color $white
  &__count-cancel
    flex-shrink 0
    color $black
    margin-left pxToRem(12px)
  &__name-cancel
    color $gray-80